.modalOverlay {
 width: 100%;
 height: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 background: rgba(0, 35, 62, 0.9);
 backdrop-filter: blur(10px);
}
.deleteActivityModal {
 min-width: 300px;
 min-height: 120px;
 background: #fff;
 border-radius: 12px;
}
.requestModal h4 {
 font-size: 22px;
 margin-bottom: 15px;
 color: black;
}
.requestModal p {
 font-size: 20px;
 color: #fff;
}
.overlay {
 width: 100%;
 height: 100vh;
 overflow: auto;
 background-color: rgb(0, 0, 0);
 /* background-color: rgba(0, 0, 0, 0.7); */
 position: fixed;
 z-index: 1031;
 left: 0px;
 top: 0px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.detailPreviewModal {
 width: 300px;
 height: auto;
 background: white;
 display: flex;
 justify-content: space-between;
 border: 1px #005190 solid;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 border-radius: 15px;
 position: relative;
 color: black;
 padding: 20px;
 border: 3px solid #005190;
 box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
 margin-top: 10px;
}
.userDetails {
 min-width: 300px;
 min-height: 350px;
}
.requestModal {
 width: 380px !important;
 height: 250px;
 background: white;
 display: flex;
 justify-content: space-between;
 border: 1px #005190 solid;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 border-radius: 15px;
 position: relative;
 color: black;
 border: 3px solid #005190;
 box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
 margin-top: 10px;
 padding: 8px !important;
}
.requestModal::before {
 content: '';
 position: absolute;
 width: 100.4%;
 height: 100.8%;
 border: 1px #005190 solid;
 z-index: -1;
 top: -1.5px;
 left: -1px;
 border-radius: 15px;
}
.crossBtnContainer {
 border-radius: 40%;
 display: flex;
 align-items: center;
 justify-content: center;

 position: absolute;
 right: 10px;
 top: 10px;
 cursor: pointer;
}
.crossBtnContainer img {
 width: 28px;
 height: 28px;
}
.crossBtnOuter img {
 width: 35px;
 height: 35px;
}
.delete_btn button {
 display: flex;
 position: relative;
 z-index: 1;
 min-width: 125px;
 padding: 20px;
 height: 44px;
 border: none;
 letter-spacing: 1px;
 display: inline-block;
 padding: 10px;
 margin: 2px;
 text-align: center;
 text-transform: uppercase;
 color: #fff;
 font-size: 14px;
 cursor: pointer;
 border-radius: 50px;
 background: #e2504d;
}
.delete_btn {
 display: flex;
 /* margin-bottom: 20px; */
}
.alertText {
 font-weight: 600;
 font-size: 22px;
 color: #fff;
 margin-bottom: 0;
}
.modalCrossBtn {
 cursor: pointer;
}
.deleteActivityModal .sub {
 font-size: 20px;
}
.del {
 text-align: center;
 margin-top: 14px;
}
.deleteActivityModal .deleteBtn {
 font-weight: 500;
 font-size: 16px;
 min-width: 130px;
 min-height: 40px;
 border-radius: 20px;
}
