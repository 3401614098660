body {
 margin: 0;
 padding: 0;
 font-family: 'Poppins', sans-serif;
}
.topnav__search {
 float: left;
 display: block;
 text-align: left;
 width: 300px;
 margin: 0;
}
.topnav {
 display: flex;
 flex-direction: row;
}
.ToolTipShow{
    position: absolute;
    background: lightgrey;
    flex-direction: column;
    display: flex;
    /* align-self: center; */
    justify-content: center;
    width: 290px;
    padding: 20px 10px;
    /* height: 100px; */
    /* align-items: center; */
    right: 41px;
}
.topnav__search input {
 display: flex;
 text-align: left;
 width: 300px;
 margin: 0;
 padding: 12px;
 border: 1px #005190 solid;
 border-radius: 20px;
}

.topnav__search i {
 display: flex;
 justify-content: space-between;
 margin-left: 90%;
 font-size: 24px;
 color: #005190;
 margin-top: -10%;
}
.customer button {
 display: flex;
 align-items: center;
 text-align: center;
 align-self: flex-end;
 color: #ffffff;
 position: absolute;
 width: 126.39px;
 height: 50px;
 margin-left: 45%;
 margin-right: 10px;
 top: 33px;
 padding-left: 40px;
 border-radius: 30px;
 background-color: #e2504d;
 font-family: 'Poppins';
 font-style: normal;
 font-weight: 700;
 font-size: 18px;
 line-height: 122.9%;
}
.stucard {
 display: flex;
 border: 1px #005190 solid;
 margin-bottom: 20px;
 margin-top: 20px;
 border-radius: 10px;
 font-size: 12px;
 color: #005190;
 font-family: 'poppins';
}
.stucard1 {
 display: block;
 align-items: flex-start;
 align-content: flex-start;
 margin-left: 5px;
 margin-right: 5px;
}
ul {
 height: auto !important;
}
.stucard1 img {
 width: 200px;
 height: 300px;
 border: 3px #005190 solid;
 border-radius: 10px;
}
.stucard1 button {
 width: 192px;
 height: 60px;
 background-color: #e2504d;
 margin-top: 20px;
 margin-bottom: 20px;
 border-radius: 30px;
 color: white;
 font-weight: bolder;
 font-size: 16px;
}

.brainytext {
 display: block;
 justify-content: center;
 text-align: center;
 font-weight: lighter;
 margin-left: 20%;
}
.brainytext input {
 width: 350px;
 height: 150px;
 border: 2px #005190 solid;
 border-radius: 10px;
}
.brainycategory {
 display: block;
 justify-content: flex-end;
 text-align: center;
 font-weight: lighter;
}
.braineybottom {
 display: flex;
 font-weight: lighter;
 text-align: flex-start;
 justify-content: space-between;
 margin-top: 70px;
}
.brainycategory select {
 height: 45px;
 width: 280px;
 border: 2px #005190 solid;
 border-radius: 10px;
 color: grey;
 padding-left: 20px;
}
.keywords {
 margin-top: 30px;
 margin-bottom: 30px;
}
.keywords input {
 width: 280px;
 height: 120px;
 border: 2px #005190 solid;
 border-radius: 10px;
 margin-top: 10px;
 margin-left: 10px;
}
.braineybottom h4 {
 display: block;
 text-align: end;
 justify-content: space-between;
 font-size: 16px;
 margin-left: 25%;
 color: #005190;
 border-bottom-style: solid;
 line-height: 30px;
 border-bottom-left-radius: 1px #005190 solid;
}
.braineybottom button {
 width: 126.39px;
 height: 50px;
 background-color: #e2504d;
 border-radius: 30px;
 color: white;
 font-weight: bolder;
 font-size: 16px;
 margin-right: 40px;
}
.stucard34 {
 display: flex;
 border: 1px #005190 solid;
 margin-bottom: 20px;
 margin-top: 20px;
 border-radius: 10px;
 font-size: 12px;
 color: #005190;
 font-family: 'poppins';
}
.stucard54 {
 display: block;
 align-items: start;
 align-content: start;
 margin-left: 5px;
}
.stucard54 img {
 width: 300px;
 height: 200px;
 border: 3px #005190 solid;
 border-radius: 10px;
}
.stucard54 button {
 display: block;
 float: left;
 position: absolute;
 align-items: center;
 text-align: center;
 width: 120px;
 height: 45px;
 border-radius: 30px;
 background-color: #e2504d;
 margin-top: 9%;
 margin-left: 20px;
 color: white;
 font-weight: bolder;

 font-size: 12px;
}
.heading {
 display: block;
 align-items: start;
 align-content: start;
 margin-left: 5px;
 margin-right: 5px;
 margin-top: 40px;
}
.heading input {
 width: 250px;
 height: 65px;
 border: 2px #005190 solid;
 border-radius: 10px;
}
.resourcecategory {
 display: block;
 justify-content: flex-end;
 text-align: center;
 font-weight: lighter;
}
.resourcecategory select {
 height: 45px;
 width: 280px;
 border: 2px #005190 solid;
 border-radius: 10px;
 color: grey;
 padding-left: 20px;
}
.resourcetext {
 display: block;
 justify-content: center;
 text-align: center;
 font-weight: lighter;
 margin-left: 20%;
 margin-top: 40px;
}
.resourcetext input {
 width: 250px;
 height: 65px;
 border: 2px #005190 solid;
 border-radius: 10px;
}
.ressource1 {
 display: block;
 align-items: flex-start;
 align-content: flex-start;
 margin-left: 7px;
 margin-right: 5px;
}
.ressource1 img {
 width: 250px;
 height: 192px;
 border: 3px #005190 solid;
 border-radius: 5px;
 margin-bottom: 20px;
}

.resourcebottom h4 {
 display: block;
 text-align: end;
 justify-content: space-between;
 font-size: 16px;
 margin-left: 25%;
 color: #005190;
 border-bottom-style: solid;
 line-height: 30px;
 border-bottom-left-radius: 1px #005190 solid;
}
.resourcebottom button {
 width: 126.39px;
 height: 50px;
 background-color: #e2504d;
 border-radius: 30px;
 color: white;
 font-weight: bolder;
 font-size: 16px;
 margin-right: 40px;
}
.resourcebottom {
 display: flex;
 font-weight: lighter;
 text-align: flex-start;
 justify-content: space-between;
 margin-top: 20px;
}
.resourcetext h3 {
 display: block;
 justify-content: start;
 text-align: start;
 font-weight: lighter;
 margin-left: 5px;
 margin-top: 40px;
}
.customer23 button {
 display: flex;
 align-items: center;
 text-align: center;
 align-self: flex-end;
 color: #ffffff;
 position: absolute;
 width: 126.39px;
 height: 50px;

 top: -7px;
 padding-left: 40px;
 border-radius: 30px;
 background-color: #e2504d;
 font-family: 'Poppins';
 font-style: normal;
 font-weight: 700;
 font-size: 18px;
 line-height: 122.9%;
}
.customer23 {
 display: flex;
 align-items: flex-end;
 text-align: center;
 align-self: flex-end;
 color: #ffffff;
 position: absolute;
 margin-left: 40%;
 margin-right: 5px;
}
thead {
 background-color: #005190;
 margin-top: 15px;
 border-radius: 20px;
 padding: 10px;

 color: white;
 text-align: center;
}

.download {
 display: flex;
 justify-content: center;
 align-content: center;
 text-align: center;
 align-items: center;
 color: #005190;
}
.CommentsSection {
 margin-left: 5%;
}
.download1 {
 display: flex;
 justify-content: flex-end;
 align-content: flex-end;
 text-align: center;
 align-items: center;
 margin-left: auto;
 margin-right: 20px;
}
.download1 button {
 display: flex;
 width: 150px;
 height: 45px;
 text-align: center;
 border-radius: 30px;
 background-color: #e2504d;
 color: whitesmoke;
 font-weight: bolder;
 padding: 12px;
}
.Select-body {
 cursor: pointer;
}
table {
 display: block !important;
 max-width: fit-content;
 width: 60%;
 scroll-behavior: auto;
 border-radius: 20px !important;
}
table tr {
 font-size: 13px;
 font-weight: bolder;
 color: black;
 border-radius: 20px !important;
 font-weight: 400;
 font-family: 'Poppins';
 padding: 10px;
}

tr:nth-child(odd) {
 background-color: #e0e0e0;
}
tr:nth-child(even) {
 background-color: #d3d3d3;
}
th {
 color: white;
 padding: 10px;
 text-align: center;
 font-weight: 400;
 font-family: 'Poppins';
 font-size: 16px;

 background-color: #005190;
}
td {
 /* text-transform: capitalize; */
 text-align: center;
 align-items: center;
 overflow: hidden;
 font-size: 14px;

 padding: 10px !important;

 text-overflow: ellipsis;
 white-space: nowrap;

 background-color: rgba(196, 191, 191, 0.61);
}
.table__data {
 text-align: left;
}
.feedbackStatus {
 width: 280px;
 margin-left: 5px;
}
.feedbackStatus2 {
 width: 300px;
}
tbody > tr:hover {
 background-color: gray;
 color: var(--txt-white);
 border: 1px grey solid;
 border-end-start-radius: 20px;
}
td img {
 width: 40px;
 height: 40px;
 border-radius: 20px;
}
.trow {
 display: table-cell;
}
.deleteuser {
 text-decoration: underline;
 color: #e2504d;
 cursor: pointer;
}
.previewuser {
 text-decoration: underline;
 color: #005190;
 font-style: normal;
 font-weight: 400;
 cursor: pointer;
}
thead tr {
 background-color: #005190;
}
.requestModal h4 {
 font-size: 22px;
 margin-bottom: 15px;
 color: black;
}
.requestModal p {
 font-size: 20px;
 color: #fff;
}
.overlay {
 width: 100%;
 height: 100vh;
 overflow: auto;
 background-color: rgb(0, 0, 0);
 /* background-color: rgba(0, 0, 0, 0.7); */
 position: fixed;
 z-index: 1031;
 left: 0px;
 top: 0px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.requestModal {
 width: 300px;
 height: 250px;
 background: white;

 display: flex;
 justify-content: space-between;
 border: 1px #005190 solid;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 border-radius: 15px;
 position: relative;
 color: black;
 border: 3px solid #005190;
 box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
 margin-top: 10px;
}
.requestModal::before {
 content: '';
 position: absolute;
 width: 100.4%;
 height: 100.8%;
 border: 1px #005190 solid;
 z-index: -1;
 top: -1.5px;
 left: -1px;
 border-radius: 15px;
}
.crossBtnContainer {
 border-radius: 40%;
 display: flex;
 align-items: center;
 justify-content: center;

 position: absolute;
 right: 10px;
 top: 10px;
 cursor: pointer;
}
.crossBtnContainer img {
 width: 28px;
 height: 28px;
}
.crossBtnOuter img {
 width: 35px;
 height: 35px;
}
.delete_btn button {
 display: flex;
 position: relative;
 z-index: 1;
 min-width: 125px;
 padding: 20px;
 height: 44px;
 border: none;
 letter-spacing: 1px;
 display: inline-block;
 padding: 10px;
 margin: 2px;
 text-align: center;
 text-transform: uppercase;
 color: #fff;
 font-size: 14px;
 cursor: pointer;
 border-radius: 50px;
 background: #e2504d;
}
.delete_btn {
 display: flex;
 margin-top: 30px;
 margin-bottom: 20px;
 justify-content: center;
}

/* analytics */

.topBarContainer {
 display: flex;
 justify-content: space-between;
 padding: 30px 0;
}
.search {
 background: #ffffff;
 border: 1px solid #005190;
 border-radius: 70px;
 min-height: 50px;
 padding-left: 20px;
 padding-right: 10px;
 max-width: 480px;
 font-weight: 400;
 font-size: 15px;
 color: #c0c0c0;
 position: relative;
}
.selectMainContainer {
 position: relative;
 width: max-content;
 display: flex;
}
.selectContainer {
 min-width: 210px;
 min-height: 50px;
 position: relative;
}
.selectContainer {
 appearance: none;
}
.searchIcon {
 cursor: pointer;
}
.searchTextArea {
 width: 90%;
 min-height: 50px;
}
.searchContainer {
 position: relative;
}
.selectContainerInput {
 border: 0px !important;
 min-width: 175px;
 background: transparent;
}
input:-webkit-autofill:active {
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-webkit-autofill {
 -webkit-box-shadow: 0 0 0 50px white inset;
 -webkit-text-fill-color: #333;
}

input:focus {
 outline: none;
 border: none;
}
.selectContainerInput:focus-visible {
 outline: -webkit-focus-ring-color auto 0px !important;
}
.userStatus {
 margin-bottom: 30px;
}
.approvalBtnOuter {
 width: 100%;
 justify-content: space-around;
}
.approvalBtn {
 background: #e2504d;
 border-radius: 50px;
 min-width: 120px;
 min-height: 46px;
 display: flex;
 align-items: center;
 justify-content: center;
 color: #fff;
 text-transform: uppercase;
 font-size: 14px;
 max-height: 48px;
}
.downloadBtn {
 background: #e2504d;
 border-radius: 50px;
 min-width: 200px;
 min-height: 46px;
 display: flex;
 align-items: center;
 justify-content: center;
 color: #fff;
 text-transform: uppercase;
 font-size: 14px;
 max-height: 48px;
}
.activeUserCard {
 min-height: 100px;
 /* min-width: 520px; */
 background: #005190;
 display: flex;
 align-items: center;
 justify-content: space-between;
 border-radius: 20px;
 padding: 0 20px;
 margin-bottom: 30px;
}
.noOfUser {
 padding-left: 10px;
 font-weight: 500;
 font-size: 16px;
 color: #fff;
 margin-bottom: 0px;
 line-height: 1;
}
.submitDate {
 justify-content: center !important;
}
.imageContainer {
 display: flex;
 align-items: center;
}
.fromTodate {
 display: block !important;
 padding: 4px;
}
.QuestionNumber {
 width: 50px;
}
.imageContainer img {
 max-height: 40px;
}
.sendButtonLoader {
 position: absolute;
 z-index: 999;
 margin-top: 8px;
}
.DataLoader {
 z-index: 999;
 margin-top: 18px;
 margin-bottom: 18px;
}
.count {
 font-weight: 500;
 font-size: 40px;
 color: #fff;
}
.weeklyCard {
 /* min-width: 400px; */
 min-height: max-content;
 background: #fff;
 box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
 border-radius: 20px;
}
.weeklyCardTopBar {
 background: #005190;
 box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.35);
 border-radius: 20px;
 min-height: 60px;
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 0 12px;
 margin-bottom: 15px;
}
.weeklyCardTopBar p {
 color: #fff;
 font-size: 20px;
 font-weight: 500;
 margin-bottom: 0px;
}
.weeklyCardList {
 display: flex;
 padding: 0 15px 10px 12px;
 flex-direction: column;
 height: auto;
 margin-bottom: 30px;
}
.weeklyCardList li {
 display: flex;
 justify-content: space-between;
 margin-bottom: 14px;
 font-weight: normal;
 color: #646464;
 font-size: 18px;
}
.viewAll {
 font-weight: 500;
 font-size: 18px;
 color: #fff;
 text-decoration: underline;
 cursor: pointer;
 background: transparent;
}
.pieChartData {
 max-width: 260px;
 max-height: 260px;
}
.pieChartContainer {
 width: 90%;
 margin: 30px auto 0 auto;
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.download {
 font-size: 12px;
 color: #646464;
 margin-left: 5px;
}
.resourceAnalysisList span {
 display: inherit;
}
.circle {
 background: #ff5c00;
 min-width: 21px;
 min-height: 21px;
 border-radius: 50%;
 margin-right: 8px;
}
.countingContainer {
 display: flex;
 align-items: center;
 margin-bottom: 20px;
}
.pieChartCircleContainer {
 display: flex;
 flex-direction: column;
}
.progressBarMainContainer {
 position: relative;
 display: flex;
 padding: 0 12px;
}
.progressBarContainer {
 position: relative;
 margin-bottom: 20px;
 margin-top: 14px;
}
.progressBarResult {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 39%;
}
.progressBarResult p {
 font-size: 40px;
 font-weight: 700;
}
.notYetStarted {
 color: #e2504d;
}
.notYetStartedSpan {
 color: #e2504d;
}
.inProgress {
 color: #ffbc00;
}
.inProgressSpan {
 color: #ffbc00;
}
.completed {
 color: #00b212;
}
.completedSpan {
 color: #00b212;
}
.progressBarResult span {
 text-transform: uppercase;
 font-weight: 400;
 font-size: 15px;
 margin-bottom: 25px;
}
.secondProgressBar {
 position: absolute;
 left: 0;
 width: 240px;
 height: 240px;
 top: 30px;
}
.thirdProgressBar {
 position: absolute;
 left: 0;
 width: 180px;
 height: 180px;
 top: 60px;
}
.urls {
 color: #005190;
}
th {
 border-right: 1px solid rgba(42, 42, 42, 0.3);
}
td {
 border: 1px solid rgba(42, 42, 42, 0.3);
}
table {
 border-spacing: 0px;
 display: block;
 width: 100%;
}
.download a {
 color: #005190;
 font-size: 18px;
 text-decoration: underline;
}
.testimonialContainer {
 border: 1px solid #005190;
 border-radius: 10px;
 padding: 20px 10px;
 width: 100%;
 display: flex;
 justify-content: space-between;
 flex-wrap: wrap;
 margin-bottom: 24px;
}
.testimonialTopBarContainer {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 margin-bottom: 32px;
}
.testimonialContentMainContainer {
 display: flex;
 align-items: center;
}
.testimonialContentContainer {
 margin-left: 10px;
}
.testimonialContentMainContainer img {
 max-width: 66px;
 max-height: 66px;
 min-height: 66px;
 min-width: 66px;
 border-radius: 50%;
}
.testimonialContentContainer p {
 color: #005190;
 font-size: 20px;
 margin-bottom: 0px;
 font-weight: 600;
}
.userId {
 font-weight: 600 !important;
}
.testimonialContentContainer span {
 color: #005190;
 font-size: 18px;
 font-weight: 400;
}
.star {
 margin-right: 12px;
}
.subtitle {
 font-weight: 400;
 font-size: 14px;
 color: #000;
}
option {
 padding: 10px 0;
}

.tab {
 color: #005190;
 font-size: 20px;
 font-weight: 400;
 background: transparent;
 padding-bottom: 6px;
 margin-bottom: 30px;
}
.sidebar__logo {
 border-bottom: 1px solid #b8860b;
}
.lineChart_card {
 margin-top: 20px;
 margin-bottom: 20px;
 padding-bottom: 12px;
}
.sidebar__item {
 /* padding: 10px; */
 font-size: 15px !important;
}
.active {
 font-weight: 600;
 border-bottom: 2px solid #005190;
}
.newForumCard {
 width: 100%;
 padding: 18px !important;
 border: 2px solid #005190;
 border-radius: 20px;
 display: flex;
}
.questionContainer h4 {
 font-weight: 700;
 font-size: 20px;
 color: #005190;
 margin-bottom: 14px;
}
.questionText {
 font-weight: 700;
 font-size: 20px;
 color: #005190;
 margin-bottom: 14px;
}
.newForumCard .testimonialContentMainContainer img {
 margin-right: 10px;
}
.newForumCard .viewAll {
 color: #005190;
}
.newForumCard .testimonialContentMainContainer .userId {
 margin-right: 24px;
}
.nextButton {
 border: 0;
 max-width: 140px;
 min-width: 140px;
}
.audioPlayer1 {
 margin-left: 10px;
}
.linkForAvtivityDetail {
 display: flex;
 align-items: center;
}
.linkForAvtivityDetail span {
 font-size: 14px;
}
.weeklyCardList span {
 font-size: 14px;
}
.activityTitle p {
 width: 290px;
}
.nextButton:hover {
 background: #e2504d;
 border: 0 !important;
}
.nextButton:focus {
 background: #e2504d;
 border: 0 !important;
}
.notificationCard {
 border: 1px solid #005190;
 border-radius: 10px;
}
.notificationCard .form-label {
 font-weight: 400;
 font-size: 18px;
 color: #005190;
}
.fromTodate .form-label {
 margin-bottom: 0px !important;
 width: 390px;
 text-align: left;
 margin-left: 25px;
 margin-top: 12px;
}
.DateInput_Form {
 text-align: center;
}
.notificationCard .form-control {
 background: #ffffff;
 border: 1px solid #005190;
 border-radius: 9px;
 font-weight: 400;
 font-size: 15px;
 color: #6e6e6e;
 min-height: 48px;
}
.notificationCard .form-select {
 border: 1px solid #005190;
 border-radius: 9px;
 font-weight: 400;
 font-size: 15px;
 color: #6e6e6e;
 min-height: 48px;
 cursor: pointer;
}
.category {
 color: #005190;
 font-weight: 400;
 font-size: 20px;
 text-transform: capitalize;
}
.sub {
 color: #8d8d8d;
 font-weight: 400;
 font-size: 16px;
 text-transform: capitalize;
}
.activityCard {
 border: 1px solid #005190;
 margin-bottom: 25px;
}
.addBtn {
 text-transform: uppercase;
 max-width: 140px;
 min-width: 140px;
}
.viewDetails {
 color: #005190;
}
.SpeakerIconContainer {
 width: 32px;
 height: 32px;
 background: #005190;
 border-radius: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
 margin-left: 20px;
}
.audioPlayerContainer {
 display: flex;
 align-items: center;
 margin-bottom: 20px;
}
.activity {
 margin-right: 20px;
 max-width: 20%;
 min-width: 12%;
 margin-bottom: 0px;
}
/* .deleteBtn {
 min-width: 205px;
} */
.modalOverlay {
 width: 100%;
 height: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 background: rgba(0, 35, 62, 0.9);
 backdrop-filter: blur(10px);
}
.deleteActivityModal {
 min-width: 300px;
 min-height: 120px;
 background: #fff;
 border-radius: 12px;
}
.ModalTopBar {
 margin-top: -8px;
 background: #e85a56;
}
.resourcesTableHead th {
 width: 400px;
}
.no-data {
 text-align: center;
 color: black;
 display: flex;
 justify-content: center;
 height: 300px;
 align-items: center;
}
.form-control:-webkit-autofill {
 -webkit-box-shadow: 0 0 0 50px white inset;
 -webkit-text-fill-color: #333;
}
.form-control:focus {
 background-color: white !important;
 border: 1px solid #005190;
 box-shadow: none;
}
.alertText {
 font-weight: 600;
 font-size: 22px;
 color: #fff;
 margin-bottom: 0;
}
.modalCrossBtn {
 cursor: pointer;
}
.deleteActivityModal .sub {
 font-size: 20px;
}
.del {
 text-align: center;
 margin-top: 14px;
}
.deleteActivityModal .deleteBtn {
 font-weight: 500;
 font-size: 16px;
 min-width: 130px;
 min-height: 40px;
 border-radius: 20px;
}
.updateBtn {
 min-width: 160px;
 max-width: 200px;
 cursor: pointer;
}
.showMoreBtn {
 margin-bottom: 20px;
}
.userImg {
 width: 86px;
 height: 86px;
 border-radius: 50px;
}
.previewModalInner {
 display: flex;
 align-items: center;
 justify-content: center;
}
.textContainer {
 /* display: flex; */
 justify-content: space-evenly;
 background: none;
 margin-left: 12px;
}
.privewModalsTableRow {
 background-color: white !important;
}
.lightText {
 color: #005190 !important;
 font-size: 18px !important;
 font-weight: 400;
 text-align: left !important;
 background-color: white !important;
 border: none !important;
}
.darkText {
 font-weight: 600 !important;
}
.previewModal {
 width: auto !important;
 max-width: none !important;
}
.usrenameTd {
 max-width: 340px;
 overflow: initial;
}
.activityCardImage {
 max-height: 318px;
}
.generateTextBody {
 flex-direction: column;
}
#preloaderload {
 position: fixed;
 width: 81%;
 height: 100%;
 top: 0;
 left: 20%;
}

.questionRow {
 display: flex;
 flex-direction: row;
 margin-bottom: 12px;
 align-items: center;
}
.questionRow h4 {
 margin-bottom: 0px !important;
 margin-left: 5px;
}

#preloaderloadAreaSpecificLoader {
 bottom: 70px;
}
#preloaderloadformodal {
 position: fixed;
 width: 100%;
 height: 100%;
 z-index: 99999;
 top: 0;
 right: 60%;
}
.preload-content {
 position: absolute;
 top: 50%;
 left: 48%;
 margin-top: -35px;
 margin-left: -35px;
}
.generateTextBody .form-control {
 max-width: 300px;
 margin: auto;
 color: #666;
 font-size: 12px;
 min-height: 48px;
 border-radius: 50px;
}
.download_bar {
 justify-content: space-between;
}
.download_label {
 display: flex;
}
.form-label1 {
 margin-top: 0em;
 padding-right: 10px;
}
.date-pick {
 width: 200px !important;
}
.date_picker .form-control {
 max-width: 390px;
 margin: auto;
 color: #666;
 font-size: 12px;
 min-height: 48px;
 border-radius: 50px;
}
.recordsOuter {
 padding-bottom: 5px !important;
}
.totalFeedback {
 justify-content: flex-end;
 text-align: end;
 margin: 0px !important;
}
.oldest_button {
 background-color: #e2504d;
 width: 210px;
 color: white;
 border-radius: 50px;
 padding: 13px;
 /* margin: auto; */
 cursor: pointer;
 text-align: center;
 /* justify-content: center; */
 /* align-items: center; */
}
.generateTextBody label {
 text-align: left;
 width: 100%;
 max-width: 300px;
}
.top_Bar {
 justify-content: space-between;
}
.star_records {
 border: 1px solid #005190;
 border-radius: 7px;
 padding: 5px 15px;
 margin: 15px 6px;
}
.totalUsers {
 width: 100%;
 text-align: right;
 margin-bottom: 0;
}
.top_bar_container .date_picker .form-control {
 margin-left: 0;
 margin-right: 0;
}
.sendBtn {
 margin-left: auto;
 min-width: 200px;
 margin-right: 26px;
 margin-top: 6px;
}
.notificationMainContainer {
 display: flex;
 flex-direction: column;
 justify-content: center;
}
.commentContainer2 {
 display: flex;
 justify-content: space-between;
}
.top_bar_container {
 padding-bottom: 20px;
}
.category {
 color: #005190;
 font-weight: 400;
 font-size: 20px;
}
.categoryForComment {
 color: #005190;
 font-weight: 400;
 font-size: 20px;
 margin-top: 15px;
}
.categoryForTotalReply {
 color: #005190;
 font-weight: 400;
 font-size: 19px;
 margin-top: 15px;
}
.categoryContainer span {
 color: #8d8d8d;
 font-size: 16px;
}
.delete_btn .viewButton {
 margin-top: 16px;
}
.buttonContainer {
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}
.buttonContainer .delete_btn {
 margin: 0px;
}
.buttonContainer .viewAll {
 /* margin-top: 15px; */
 font-size: 15px;
 /* margin-right: 15px; */
}
.buttonContainer .delete_btn button {
 min-width: 120px;
 /* font-weight: 600; */
 font-size: 14px;
 top: 15px;
}
.commentContainer {
 display: flex;
 flex-direction: column;
}
.commentContainer span {
 color: #005190;
 margin-bottom: 8px;
}
.userId {
 font-weight: 600;
 font-size: 18px;
}
.date {
 font-size: 15px;
 font-weight: normal;
}
.audioPlayer {
 margin-bottom: 12px;
}
.newForumCard .commentButton {
 color: #e85a56;
 background: transparent;
 text-align: left;
 margin-bottom: 10px;
}
.responsesText {
 color: #8d8d8d;
 font-size: 13px;
 margin-left: 5px;
 margin-bottom: 5px;
}
.commentAnswerContainer2 {
 /* margin-left: 5%; */
 display: flex;
 flex-direction: column;
}
.commentAnswerContainer {
 /* margin-left: 5%; */
 display: flex;
 flex-direction: column;
}
.deleteBtn {
 margin-bottom: 0px;
 margin-top: 50px;
}
.question_container h4 {
 margin-bottom: 30px;
}
.mainContainer {
 width: 100%;
 min-height: 100vh;
 display: flex;
 align-items: center;
 justify-content: center;
}
.delete {
 justify-content: end;
}
.buttonsContainer {
 display: flex;
 justify-content: center;
 margin-bottom: 20px;
 align-items: baseline;
}
/* .backButton {
 width: 32px;
 height: 32px;
} */
.delete_Button {
 min-width: 160px;
}
.backArrow {
 height: 43px;
 width: 43px;
 cursor: pointer;
}
.viewForumTop {
 align-items: center;
}
.userDetails tr:nth-child(odd) {
 background-color: transparent;
}
.userDetails .privewModalsTableRow {
 background: transparent;
}
.questionContainer {
 display: flex;
 flex-direction: column;
 align-items: center;
}
