.modal {
 display: block;
 letter-spacing: 3px;
 max-width: 32%;
 height: auto;
 border-radius: 20px;
 -ms-scroll-chaining: scroll;
 overscroll-behavior: scroll;
 position: relative;
 background-color: white;
 padding: 20px;
}
.loginModal {
 max-width: 35%;
}
.logo__image {
 height: auto;
 width: 100px;
 margin-bottom: 22px;
}
.overlay {
 width: 100%;
 height: 100vh;
 overflow: auto;
 background-color: rgba(0, 35, 62, 0.9);
 position: fixed;
 z-index: 1031;
 left: 0;
 top: 0;
 display: flex;
 align-items: center;
 justify-content: center;
}
.btnContainer {
 display: flex;
 justify-content: center;
}
.login-btn {
 position: relative;
 z-index: 1;
 width: 120px;
 padding: 12px 0px;
 font-size: 14px;
 font-weight: 500;
 border: none;
 letter-spacing: 1px;
 display: inline-block;
 text-align: center;
 text-transform: uppercase;
 color: #fff;
 border-radius: 50px;
 background-color: #e2504d;
 cursor: pointer;
 margin-top: 16px;
}
.loginBtnOuter {
 text-align: center;
}
#exampleInputEmail2 {
 display: block;
 /* width: 100%; */
 padding: 8px 18px;
 font-size: 14px;
 font-weight: 400;
 line-height: 1.5;
 color: #212529;
 background-color: #fff;
 background-clip: padding-box;
 border: 2px solid #005190;
 -webkit-appearance: none;
 -moz-appearance: none;
 appearance: none;
 transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 max-width: 300px;
 margin: auto;
 margin-bottom: 20px;
}
#exampleInputEmail1 {
 display: block;
 padding: 8px 18px;
 font-size: 14px;
 font-weight: 400;
 line-height: 1.5;
 color: #212529;
 background-color: #fff;
 background-clip: padding-box;
 border: 2px solid #005190;
 -webkit-appearance: none;
 -moz-appearance: none;
 appearance: none;
 transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 max-width: 300px;
 margin: auto;
 margin-bottom: 20px;
}
.login-text {
 color: #005190;
 font-weight: 600;
 text-align: left;
 max-width: 275px;
 letter-spacing: 0.4px;
 font-size: 18px;
 margin: 0px auto 8px auto;
}

.logoOuter {
 text-align: center;
}
.form {
 padding: 5px;
 margin: 0px;
 justify-content: center;
 background: white;
 border-radius: 30px;
}

.form h2 {
 text-align: center;
}
.form button {
 display: flex;
 justify-content: center;
 padding: 10px;
 /* margin-left: 35%; */
 border-radius: 10px;
}
input {
 padding: 10px;
 border-radius: 50px;
 background-color: white;
}
