.layout {
 color: var(--txt-color);
}

.layout__content {
 padding-left: var(--sidebar-width);
 background-color: var(--second-bg);
 min-height: 100vh;
}

.layout__content-main {
 margin-right: 20px;
}
