@media only screen and(max-width:991px) {
 .heading {
  margin-left: 30%;
 }
 .table {
  display: block;
 }

 .content {
  font-size: 20px;
 }
 .previewcard {
  margin-left: 0px;
 }
}
@media only screen and(min-width:576px) {
 .previewcard {
  margin-left: 0px;
 }
 .itemdetail-img img {
  margin-left: 20px;
 }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
 .loginModal {
  max-width: 60%;
 }
}
@media only screen and (max-width: 767px) {
 .loginModal {
  max-width: 70%;
 }
}
