/* .sidebar {
 width: 20%;
 height: 100%;
 min-height: 100%;
 position: fixed;
 overflow: auto;
 background-color: #005190;
 padding-bottom: 80px;
 z-index: 9;
} */

.sidebar {
 min-width: 260px;
 width: auto;
 height: 100%;
 position: fixed;
 left: 0;
 top: 0;
 background-color: #005190;
 box-shadow: var(--box-shadow);
 overflow: auto;
}
.sidebar__logo {
 height: 110px;
 display: flex;
 align-items: center;
}
.companytitle {
 font-size: 60px;
 margin-left: 20px;
 background: -webkit-linear-gradient(
  rgba(221, 205, 112, 0.966),
  rgba(228, 210, 108, 0.986)
 );
 background-clip: text;
 margin-left: 30px;
 -webkit-text-fill-color: transparent;
 font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.companyimg img {
 max-width: 100px;
 max-height: 70px;
 margin-left: 20%;
 margin-top: 15px;
 margin-left: 25px;
}

.sidebar__item {
 padding: 10px;
 font-size: 15px;
 top: 30%;
 color: white;
}

.sidebar__item-inner {
 padding: 10px;
 display: flex;
 align-items: center;
 font-weight: 600;
 transition: color 0.3s ease 0s;
}

.sidebar__item-inner > button {
 margin-right: 10px;
 font-size: 14px;
}

.sidebar__item-inner > span {
 text-transform: capitalize;
 margin-left: 8px;
}

.sidebar__item-inner:hover {
 color: whitesmoke;
}

.sidebar__item-inner.active {
 border: 1px solid white;
 border-radius: 50px;
 color: white;
}
.sidebar button {
 display: flex;
 align-items: center;
 justify-content: center;
 min-width: 120px;
 min-height: 44px;
 border-radius: 50px;
 text-align: center;
 margin: 30px auto 10px auto;
 color: #fff;
 font-size: 14px;
}
.logout {
 background-color: #e2504d;
}
.closemenu {
 display: none;
}

@media (max-width: 767px) {
 .closemenu {
  background: #fff;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 9999;
 }
 .pro-sidebar.md {
  left: -310px !important;
 }
 .pro-sidebar.md.toggled {
  left: 0px !important;
 }
 .layout__content {
  padding-left: 0px !important;
 }
 .closemenu img {
  width: 26px;
  height: 26px;
 }
}
