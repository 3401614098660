.table-wrapper {
 height: auto;
 width: 100%;
 overflow: scroll;
 scroll-behavior: auto;
 scroll-snap-type: thin mandatory;
 height: 400px;
}

.table-heading {
 position: relative;
 z-index: 1030;
}

.preload-content {
 position: absolute;
 top: 50%;
 left: 50%;
 margin-top: -35px;
 margin-left: -35px;
 z-index: 100;
}
.animation-loader {
 height: 120px;
 width: 160px;
}
.scrollbar {
 align-items: right;
 width: 10px;
 color: black;
 box-shadow: inset 0 0 5px grey;
 border-radius: 10px;
}
.cardimg_div {
 display: flex;
 justify-content: center;
}
table {
 grid-template-rows: auto;
 overflow: auto;
 /* border-radius: 20px; */
 width: 100%;
 display: block;
}
.modaloverlay {
 display: flex;
 position: absolute;
 justify-content: center;
 justify-items: center;
 width: 400px;
 height: 350px;
 padding-top: 30px;
 padding-bottom: 30px;
 color: black;
 background-color: white;
 border: 2px solid rgb(225 176 15 / 75%);
 margin-top: 5%;
 border-radius: 15px;
 margin-bottom: 10%;
}
.modaltitle123 {
 color: black;
 padding-bottom: 10px;
 margin-top: 10px;
 margin-bottom: 10px;
 margin-left: 40px;
}
/* thead {
 background-color: black;
 margin-top: 15px;
 border-radius: 30px;
 padding: 5px;
 color: white;
 text-align: center;
} */
.card {
 padding: 10px;
}
.cardbtn1 {
 margin-top: 20%;
}

.cardbtn1 button {
 display: flex;
 justify-items: center;
 text-align: center;
 justify-content: center;
 align-items: center;
 background-color: black;
 padding: 14px;
 width: 120px;
 border-radius: 7px;
 font-size: 12px;
 margin-top: 20px;
 margin-left: 20px;
 color: white;
}
.customereventButton12 button {
 text-align: center;
 justify-content: center;
 background-color: black;
 padding: 16px;
 color: white;
 border-radius: 10px;
 margin-left: auto;
 margin-right: 100px;
}
.searchbar {
 display: flex;
 justify-items: flex-start;
}
.searchbar input {
 padding: 16px;
 margin-left: 10px;
 width: 270px;
 height: 45px;
 border: 2px black solid;
 text-align: start;
 color: black;
 border-radius: 10px;
 background-color: white;
}
.searchbar button {
 display: flex;
 justify-items: flex-end;
 text-align: center;
 justify-content: center;
 background-color: black;
 color: white;
 border-radius: 10px;
 width: 70px;
 margin-left: 10px;
 height: 45px;
 padding-top: 12px;
}
.dropdown14 select {
 display: flex;

 text-align: center;

 font-size: larger;
 width: 160px;
 border-radius: 10px;
 margin-top: 20%;
 height: 40px;
 padding: 7px;

 margin-right: 20px;
}
/* tr {
 text-size-adjust: auto;
 padding-left: 10%;
 font-size: 13px;
 font-weight: bolder;
 color: black;
 border-radius: 20px;
 word-spacing: 2px;
 white-space: nowrap;
} */
.desc_fooddrink {
 max-height: 30px;
 min-height: 30px;
}
th {
 text-transform: capitalize;
 padding: 10px 15px;
 font-size: 16px !important;
 color: white;
 text-align: center;
}
.itemDescription {
 max-width: 100px;
 min-width: 380px;
 /* display: flex; */
 /* align-items: end; */
 /* justify-content: center; */
 margin: auto;
}
.itemDescription-heading {
 max-height: 200px;
 overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 3;
 -webkit-box-orient: vertical;
}
td {
 /* text-transform: capitalize; */
 text-align: center;
 align-items: center;
 overflow: hidden;
 font-size: 14px;
 padding: 5px 25px;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.table__data {
 text-align: left;
}
tbody > tr:hover {
 background-color: gray;
 color: var(--txt-white);
 border: 1px grey solid;
 border-end-start-radius: 20px;
}

.table__pagination {
 display: flex;
 width: 100%;
 justify-content: flex-end;
 align-items: center;
 margin-top: 20px;
}

.table__pagination-item ~ .table__pagination-item {
 margin-left: 10px;
}

.table__pagination-item {
 width: 30px;
 height: 30px;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
}
/* .clickhere button {
 margin-top: 50px;
 top: 30%;
} */
.table__pagination-item.active,
.table__pagination-item.active:hover {
 background-color: var(--main-color);
 color: var(--txt-white);
 font-weight: 600;
}

.table__pagination-item:hover {
 color: var(--txt-white);
 background-color: var(--second-color);
}
.eventButton {
 display: flex;
 justify-content: flex-end;
}
.customer__search {
 /* position: relative; */
 height: 30px;
 border: 2px rgba(15, 4, 2, 0.897) solid;
 background-color: white;
 display: flex;
 align-items: center;
 width: 200px;
 margin-left: 3%;
 border-radius: 5px;
}
.customer__search input {
 font-size: 8px;

 margin-left: 8px;
 text-overflow: auto;
 width: 100%;
}
.customer__search > i {
 font-size: 1.5rem;
 position: absolute;
 right: 3px;
}

.updateproduct {
 padding: 5%;
 font-size: 10px;
 font-weight: bolder;
 color: white;
 background-color: black;
 border-radius: 5px;
 margin: 2px;
 margin-left: 20%;
 text-align: center;
}
.FOOD {
 font-size: 16px;

 color: #005190;
 padding: 20px;
}
.DRINKS {
 font-size: 16px;

 color: #005190;
 padding: 20px;
}
.cardimg {
 border-radius: 5px;

 margin-bottom: 12px;
}
.event_text h3 {
 margin: 10px 0px !important;
}
.card_wraper {
 padding: 0px 5px;
}
.eventcard_text2 {
 display: flex;
 justify-content: space-between;
 padding: 0px 5px;
}
.eventcard_text1 {
 display: flex;
 justify-content: space-between;
}
.eventcard_text2 h3 {
 margin: 10px 0px !important;
}
.eventcard_text1 h3 {
 margin: 10px 0px !important;
}
.eventcard_text1 .cardhead {
 margin: 10px 0px !important;
}
.event-cardimg {
 width: 185px;
 margin: auto !important;
}
.cardimg img {
 height: 120px;
 border-radius: 5px;
 margin-top: 3px;
 margin-left: 3px;
 margin-right: 7px;
}
.foodcard1 {
 border: 2px rgba(48, 40, 40, 0.123) solid;
 margin-top: 12px;
 margin-bottom: 2px;
 /* overflow: hidden; */
 text-overflow: ellipsis;
 white-space: nowrap;
 border-radius: 7px;
 /* height: 290px; */
 width: 200px;
 padding: 3px;
 display: grid;
 gap: 1rem;
 grid-auto-flow: column;
 grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
 background-color: rgba(0, 0, 0, 0.836);
}
.modal5 {
 display: block;
 justify-content: center;
}
.merchantname h5 {
 color: white;
 font-size: 20px;
 text-align: right;
 margin-right: 0px;
 margin-left: auto;
}
.merchantname {
 display: flex;
 justify-content: flex-end;
 width: 100%;
}
.customer__filter {
 display: flex;
 margin-left: auto;
 margin-right: 0px;
 /* margin-top: 30px; */
}
.customer__filter button {
 display: flex;
 border-radius: 10px;
 font-size: 12px;
 background-color: black;
 color: white;
 padding: 10px;
 margin-left: auto;
 margin-right: 0px;
}
.customer__filter1 button {
 display: flex;
 align-items: center;
 border-radius: 10px;
 font-size: 15px;
 background-color: black;
 color: white;
 padding: 10px;
 margin-left: auto;
 margin-right: 0px;
}
.customer__filter2 button {
 display: flex;
 justify-content: center;
 border-radius: 10px;
 font-size: 12px;
 background-color: black;
 color: white;
 padding: 18px;
 margin-left: auto;
 margin-right: 0px;
}
.customer__filter img {
 display: flex;
 justify-items: center;
 width: 25px;
 height: 30px;
}
.customer__filter h5 {
 margin-top: 5px;
 margin-left: 5px;
 font-size: 16px;
}
.itemDescription h4 {
 display: flex;
 flex-direction: column;
 margin-left: 4%;
 /* margin: auto; */
 margin-right: 4%;
 padding: 5px;
}

.itemdetail-img img {
 width: 100%;
 height: 306px;
 border-radius: 10px;
 margin-top: 10px;
}
.cardheader h3 {
 font-size: 20px;
 margin-left: 18px;
 color: white;
 text-align-last: left;

 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.cardheaderr h3 {
 font-size: 12px;
 margin-left: 5px;
 color: white;
 text-align-last: left;
 width: 170px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.cardprice {
 color: white;
 font-size: 12px;
 flex: end;
 margin-left: 10%;
}

.cardhead {
 font-size: 12px;
 margin-left: 20px;
 color: white;
 text-align-last: left;
 text-overflow: ellipsis;
 white-space: nowrap;
 font-weight: bolder;
 max-width: 160px;
 margin-bottom: 5px;
}
.carddetails h5 {
 color: white;
 width: 200px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
 font-size: 12px;
 letter-spacing: 2px;
 margin-top: 7px;
 margin-bottom: 3px;
 /* margin-left: 5px; */
 margin-right: 5px;
}
.cardbtn button {
 display: flex;
 justify-items: center;
 align-items: center;
 background-color: black;
 padding: 15px;
 border-radius: 7px;
 font-size: 12px;
 margin-top: 22px;
 margin-bottom: 3px;
 color: white;
}

.salegraph1 {
 background-color: black;
 margin: 10px;
 border-radius: 5px;
 height: 300px;
 padding-top: 10px;
 color: white;
}
.salegraph2 {
 background-color: black;
 margin: 10px;
 padding-top: 10px;
 border-radius: 5px;
 height: 300px;
 color: white;
}
.salegraph1 h4,
h3 {
 margin: 10px;
}

.salegraph2 h4,
h3 {
 margin: 10px;
}
.tablehead {
 border-radius: 10px;
}

/* .layout__content-main td {
 text-align: left;
} */
.events button {
 display: flex;
 justify-items: center;
 align-items: center;
 color: white;
 padding: 15px;
 border-radius: 10px;
 background-color: black;
 margin-bottom: 5px;
 font-size: 12px;

 /* font-weight: bolder */
}
.events_btn {
 display: flex;
 justify-content: center;
}
.cust button {
 display: flex;
 justify-items: center;
 align-items: center;
 color: white;
 padding: 10px;
 border-radius: 10px;
 background-color: black;
 margin-bottom: 5px;
 font-size: 12px;
}
.eventsdetails {
 background-color: white;
 color: white;
 margin-left: 20px;
 align-items: center;
 text-align: center;
 max-width: 700px;
 max-height: 700px;
 border-radius: 5px;
}
.cardroot {
 max-width: 400px;
 margin: 10%;
 padding-top: 5px;
 padding-bottom: 5px;
 text-align: center;
 background-color: white;
 max-height: 750px;
 box-shadow: red;
 border-radius: 10px;
 padding-left: 20px;
}

.cardroot h2 {
 font-size: 20px;
 font-family: Arial, Helvetica, sans-serif;
 color: black;
}

.cardroot img {
 max-width: 250px;
 max-height: 330px;
 margin-right: 4%;
 border-radius: 10px;
 margin-bottom: 10px;
}
.cardsow {
 font-size: 14px;
 margin-left: 8px;
 color: white;
 text-align-last: left;
 text-overflow: ellipsis;
 white-space: nowrap;
 font-weight: bolder;
 content: '\a';
 white-space: pre;
 line-clamp: 3;
 margin-bottom: 5px;
 word-break: break-all;
}
.cardsow p {
 content: '\a';
 overflow: hidden;
 text-decoration-line: none;
 white-space: pre-line;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 3;
 font-size: 12px;
 justify-content: safe;
 -webkit-box-orient: vertical;
}
.foodcard {
 border: 2px rgba(48, 40, 40, 0.123) solid;
 margin-top: 12px;
 margin-bottom: 2px;
 overflow: hidden;

 text-overflow: ellipsis;
 white-space: pre;
 border-radius: 7px;
 height: auto;
 width: 200px;
 padding: 3px;

 background-color: rgba(0, 0, 0, 0.836);
}
.eventText h3 {
 font-size: 12px;
 margin-left: 18px;
 color: white;
 text-align-last: left;
 width: 180px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.foodcard34 {
 display: grid;
 gap: 1rem;
 grid-auto-flow: column;
 margin: 0 0 1rem 0;
}
.item {
 margin: 5%;
 color: black;
}
.heads {
 font-size: 16px;
 color: black;
 text-align: center;
 margin-left: 4%;
}
.detail {
 font-size: 12px;
 margin-top: 3px;
 text-align: center;
 font-weight: bolder;
 margin-bottom: 10px;
 margin-left: 3px;
}
.cardroot23 {
 max-width: 300px;
 padding-top: 5px;
 padding-bottom: 5px;
 text-align: center;
 background-color: white;
 max-height: 750px;
 border-radius: 10px;
 padding-left: 20px;
 margin-left: 17%;
}
.cardroot23 h2 {
 font-size: 20px;
 font-family: Arial, Helvetica, sans-serif;
 color: black;
}
.heads1 {
 font-size: 16px;
 color: black;
 text-align: center;
 margin-left: 30%;
}
.detail1 {
 font-size: 12px;
 margin-top: 3px;
 text-align: center;
 font-weight: bolder;
 margin-bottom: 10px;
 margin-left: 3px;
}
.custdelete img {
 height: 100px;
 width: 100px;
 background-color: 'black';
 justify-content: 'center';
 align-self: 'center';
}
.cardbtn {
 justify-content: center;
 align-items: center;
 align-content: center;
 display: flex;
}
.editbutton {
 margin-left: 0px !important;

 background-color: black;
}
.eventdelete {
 justify-content: 'center';
 align-self: center;
 display: flex;
 margin-top: 15px;
 margin-left: 15px;
}
.eventdelete img {
 margin-top: 2px;
 height: 35px;
 width: 35px;
}
.itemDetail-cardouter {
 display: flex;
 justify-content: center;
}
.previewcard {
 width: 64%;
 border: 1px solid #005190;
}
.itemdetail-img {
 max-height: 340px;
 max-width: 100px;
 min-height: 340px;
 min-width: 380px;
 display: flex;
 margin: auto;
}
.previewcard_wraper {
 display: flex;
 flex-direction: column;
 height: auto;
 width: auto;
 align-items: center;
}
.userinput input {
 border: 1px solid black;
 border-radius: 10px;
 padding: 10px;
 width: 220px;
}
.foodinput input {
 border: 1px solid black;
 border-radius: 10px;
 width: 300px;
 padding: 10px;
}
.heading {
 display: flex;
 justify-content: flex-start;
}
.foodinput textarea {
 width: 300px;
 border-radius: 10px;
 padding: 10px;
}
.foodinput {
 margin-top: 10px;
 margin-bottom: 30px;
}
.dropdown select {
 max-width: 400px;
 min-width: 300px;
 padding: 10px;
 border-radius: 10px;
 margin-bottom: 20px;
}
.addfoodevent h2 {
 margin-left: 33%;
 color: black;
 margin-bottom: 3%;
}
.content_div h4 {
 padding: 10px 0px;
}
.content_div {
 display: flex;
 justify-content: space-between;
}
.createbutton button {
 padding: 15px 10px;
 width: 150px;
 display: flex;
 align-items: center;
 align-self: center;
 text-align: center;
 border-radius: 10px;
 background-image: linear-gradient(
  130deg,
  rgb(225 176 15 / 75%) 0%,
  rgb(228 210 108 / 99%) 50%,
  rgb(225 176 15 / 75%) 100%
 );
}
.createbutton button h5 {
 margin-left: 30%;
}
.dropdown1 select {
 display: flex;
 justify-content: center;
 text-align: center;
 justify-items: center;
 height: 50px;
 width: 120px;
 border-radius: 10px;
 margin-bottom: 30px;
}
.createbutton1 button {
 margin-left: 35%;
 padding: 15px 10px;
 width: 150px;
 font-size: 20px;
 color: black;
 display: flex;
 padding-left: 35px;
 align-items: center;
 align-self: center;
 text-align: center;
 align-content: center;
 border-radius: 10px;
 background-image: linear-gradient(
  130deg,
  rgb(225 176 15 / 75%) 0%,
  rgb(228 210 108 / 99%) 50%,
  rgb(225 176 15 / 75%) 100%
 );
}

.select_description {
 margin-bottom: 10px;
}
.couponEnable {
 width: '300px';
 background-image: linear-gradient(
  130deg,
  rgb(225 176 15 / 75%) 0%,
  rgb(228 210 108 / 99%) 50%,
  rgb(225 176 15 / 75%) 100%
 );
 color: black;
 border-radius: 10px;

 display: flex;
 align-items: center;
 justify-content: center;
 border: 1px solid black;
}
.couponDisable {
 width: '300px';
 background-image: linear-gradient(
  130deg,
  rgb(225 176 15 / 75%) 0%,
  rgb(228 210 108 / 99%) 50%,
  rgb(225 176 15 / 75%) 100%
 );
 color: red;
 border-radius: 10px;
 display: flex;
 align-items: center;
 justify-content: center;
 border: 1px solid black;
}
.salesdescription {
 text-align-last: center;
 width: 180px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.username {
 margin-top: 10px;
}
.content {
 font-size: 20px;
}
.card_inner {
 display: flex;
 justify-content: space-between;
 width: 100%;
}
.content2 {
 font-size: 15px;
}
.card_inner div {
 max-width: 197px;
 min-width: 197px;
}
.data-element {
 display: flex;
 align-items: center;
}
.orderCard {
 width: 75% !important;
}
.generatetexttitle {
 text-justify: center;
 text-align: center;
 margin-top: 20px;
}
.generatetexttitle h2 {
 display: flex;
 text-justify: center;
 text-align: center;
 color: #005190;
 display: contents;
 font-size: 24px;
}
.generatetextbody {
 display: flex;
 justify-content: center;
 align-items: center;
 width: auto;
 height: auto;
 padding-top: 15px;
}

.generatetextbody input {
 font-size: 12px;
 border-radius: 10px;
 padding: 14px;
 color: black;
 line-clamp: 10;
 width: 300px;
}
.generatetextbody .form-control {
 border: 1px solid #005190;
 border-radius: 50px;
}
.btnsubmit {
 display: flex;
 justify-items: center;
 align-items: center;
 text-align: center;
 justify-content: center;
 margin-bottom: 10px;
 margin-top: 10px;
}
.btnsubmit button {
 display: flex;
 justify-items: center;
 align-items: center;
 text-align: center;
 justify-content: center;
 background: -webkit-linear-gradient(
  rgba(221, 205, 112, 0.966),
  rgba(189, 164, 25, 0.808)
 );
 padding: 8px 15px;
 margin-top: 15px;
 border-radius: 10px;
 color: black;
 width: 100px;
}
.generatemsg {
 background-color: white;
 width: 300px;
 height: 400px;
 justify-content: center;
 margin: 35%;
 text-align: center;
 padding-top: 50px;
 padding-bottom: 40px;
}
.custdeta {
 height: 500px;
 width: 450px;
 border: 2px solid black;
 margin-left: 20%;
 margin-top: 20%;
}
.detailsbtn button {
 background-color: black;
 padding: 15px;
 border-radius: 10px;
 /* margin-left: 20%; */
 width: 150px;
 height: 50;
 color: white;
 /* justify-self: center; */
}
.customerdetails {
 display: flex;
 justify-content: center;
 color: black;
 font-size: 20px;
}
.custdata234 {
 background-color: black;
 position: absolute;
 color: white;
 align-self: center;
 width: 100%;
 display: flex;
 height: 300px;
 width: 200px;
 margin-left: 5%;
 margin-right: 10%;
}
.crossBtnOuter {
 width: 38px;
 height: 38px;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 background: rgba(135, 135, 135, 0.5);
 position: absolute;
 right: 20px;
 top: 15px;
 cursor: pointer;
}
.requestModal {
 width: 40%;
 /* background: #0d1124; */
 padding: 20px;
 color: white;
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 max-width: 770px;
 border-radius: 15px;
 position: relative;
 max-height: 450px;
 margin-top: 30px;
}
.modalOverlay {
 width: 100%;
 height: 100vh;
 overflow: auto;

 position: fixed;
 z-index: 1031;
 left: 0px;
 top: 0px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.submitbtn123 {
 /* background-color: black; */
 padding: 15px;
 border-radius: 10px;
 margin-top: 10px;
 margin-bottom: 10px;
 color: var(--txt-color);
 font-size: 15px;
 font-weight: 500;
 text-transform: uppercase;
 width: 100px;
 background-image: linear-gradient(
  130deg,
  rgb(225 176 15 / 75%) 0%,
  rgb(228 210 108 / 99%) 50%,
  rgb(225 176 15 / 75%) 100%
 );
}
.detailsbtn34 button {
 margin-bottom: 5%;
 background-color: black;
 border-radius: 10px;
 display: flex;
 /* align-items: center;'' */
 width: 200px;
 height: 45px;
 font-weight: 30px;
 color: white;
 justify-content: center;
 align-items: center;
 padding: 11px;
}
.custdate234 {
 padding: 10px;
 flex-direction: row;
}
.modalOverlay12 {
 width: 100%;
 height: 100vh;
 overflow: auto;
 background-color: rgb(0, 0, 0);
 background-color: rgba(0, 0, 0, 0.7);
 position: fixed;
 z-index: 1031;
 left: 0px;
 top: 0px;
 display: flex;
 align-items: center;
 justify-content: center;
}

.modal__list {
 width: 300px;
 background-color: black;
 color: black;
 border-radius: 10px;
 display: flex;
 align-items: center;
 justify-content: center;
 border: 1px solid black;
}
li div {
 margin: 15px 10px;
}
.modal_main {
 border: 2px solid rgb(225 176 15 / 75%);
 width: 70%;
}
.modaltitle {
 color: white;
 padding: 10px;
 /* margin-top: 20px; */
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
}
.requestModal12 {
 width: 40%;
 background: #0d1124;
 padding: 20px;
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 max-width: 770px;
 border-radius: 15px;
 position: relative;
 max-height: 550px;
 margin-top: 30px;
 color: white;
}
.modalinput input {
 width: 300px;
 height: 40px;
 border-radius: 10px;
 /* margin-left: 10px; */
 margin-top: 20px;
 margin-bottom: 20px;
 padding: 5px;
}
.moddescription {
 color: white;
}
.details23 {
 color: black;
 display: flex;
 justify-content: space-between;
 width: 50%;
}
.details67 {
 color: black;
 display: flex;
 justify-content: center;
}
.userdetails {
 padding: 10px 0px;
 align-items: center;
 display: flex;
 justify-content: space-between;
 width: 100%;
}

.userinput textarea {
 border-radius: 10px;
 padding: 15px;
 width: 220px;
}
@media only screen and(max-width:991px) {
 .heading {
  margin-left: 30%;
 }
 .table {
  display: block;
 }
 .content {
  font-size: 20px;
 }
 .previewcard {
  margin-left: 0px;
 }
 .itemdetail-img {
  padding: 2px;
  margin: 2px;
 }
 .itemdetail-img img {
  padding: 5px;
  margin: 5px;
 }
 .itemDescription {
  padding: 10px;
  margin: 10px;
 }
 .foodinput input {
  margin-left: 5px;
  margin-right: 5px;
 }
 .carddatas {
  display: contents;
  justify-content: center;
  color: black;
 }
 .userinput input {
  margin: 5px;
 }
 .generatetextbody input {
  margin-left: 2px;
  margin-right: 2px;
 }
 .generatetextbody {
  margin-left: 2px;
  margin-right: 2px;
  min-width: 200px;
 }
}
@media only screen and(min-width:576px) {
 .previewcard {
  margin-left: 0px;
 }
 .itemdetail-img img {
  margin-left: 20px;
 }
 .generatetextbody input {
  margin-left: 2px;
  margin-right: 2px;
  /* min-width: 200px; */
 }
 .generatetextbody {
  margin-left: 2px;
  margin-right: 2px;
 }
 .sidebar {
  position: absolute;
  overflow: auto;
  background-color: black;
 }

 .sidebar__logo {
  display: flex;
  align-items: center;
  border-bottom: 1px solid darkgoldenrod;
 }
 .companytitle {
  font-size: 46px;
  margin-left: 20px;
 }
 .companyimg img {
  margin-left: 4px;
 }

 .sidebar__item {
  padding: 10px;
  font-size: 10px;
 }

 .sidebar__item-inner {
  padding: 10px;
 }

 .sidebar__item-inner > button {
  margin-right: 10px;
  font-size: 14px;
 }

 .sidebar button {
  padding: 10px;
 }
}
@media (min-width: 768px) and (max-width: 991px) {
 .previewcard {
  margin-left: 0px;
 }
 .itemdetail-img img {
  margin: -2px;
 }
 .generatetextbody input {
  margin-left: 10px;
  margin-right: 10px;
  /* max-width: 200px; */
 }
 .generatetextbody {
  margin-left: 20px;
  margin-right: 20px;
 }
 .sidebar {
  padding: 4px;
 }
 .dropdown1 select {
  margin-left: 20px;
 }
 .status-card {
  margin: 2px;
 }
 .foodinput input {
  margin-left: 5px;
  margin-right: 5px;
 }
 .customer__filter {
  margin-top: 20px;
  margin-left: -30px;
 }
 .modal__list {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: black;
  border-radius: 10px;

  border: 1px solid black;
 }
 .customereventButton12 button {
  margin-top: 10px;
  margin-bottom: 5px;
 }
 .sidebar__logo {
  border-bottom: 1px solid darkgoldenrod;
 }
 .companytitle {
  font-size: 46px;
  margin-left: 20px;
 }
 .searchbar input {
  margin: 2px;
 }

 .sidebar__item {
  padding: 10px;
  font-size: 10px;
 }

 .sidebar__item-inner {
  padding: 10px;
 }

 .sidebar__item-inner > button {
  margin-right: 10px;
  font-size: 14px;
 }

 .sidebar button {
  padding: 10px;
 }
 .li div {
  margin: 5px;
 }
 .modal__list ul li {
  margin-left: 3px;
 }
}
@media (min-width: 991px) and (max-width: 1280px) {
 .previewcard {
  margin: 1 0px;
 }
 .itemdetail-img img {
  margin: -2px;
 }
 .generatetextbody input {
  margin-left: 10px;
  margin-right: 10px;
  /* max-width: 200px; */
 }
 .generatetextbody {
  margin-left: 20px;
  margin-right: 20px;
 }
 .sidebar {
  padding: 4px;
 }
 .modal__list {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: black;
  border-radius: 10px;

  border: 1px solid black;
 }
 .userinput input {
  display: flex;
  justify-content: center;
  text-justify: center;
 }
 .sidebar__logo {
  border-bottom: 1px solid darkgoldenrod;
 }
 .companytitle {
  font-size: 46px;
  margin-left: 20px;
 }
 .companyimg img {
  margin: 10px;
  /* width: 60px;
  height: 35px; */
 }
 .customereventButton12 button {
  margin: 2px;
  margin-top: 10px;
  margin-left: auto;
 }
 .sidebar__item {
  padding: 10px;
  font-size: 10px;
 }

 .sidebar__item-inner {
  padding: 10px;
 }

 .sidebar__item-inner > button {
  margin-right: 10px;
  font-size: 14px;
 }

 .sidebar button {
  padding: 10px;
 }
 .li div {
  margin: 5px;
 }
 .modal__list ul li {
  margin-left: 3px;
 }
}
